import React from "react";
import { useSelector } from "react-redux";
import CommonTable from "./CommonTable";
import Loading from "./Loading";

const DynamicTab = () => {
  const { data, loading, error } = useSelector((state) => state.case);

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <>
          <CommonTable rows={data} />
        </>
      )}
    </div>
  );
};

export default DynamicTab;
