import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { environment } from "../environment";
const backendUrl = process.env.REACT_APP_BACKEND_URL;
// console.log("Backend URL:", backendUrl);

export const getDataForEachTab = createAsyncThunk("getDataForEachTab", async (name, { rejectWithValue }) => {
  try {
    // const response = await axios.get(`${backendUrl}getDataForEachTab/${name}`);
    const response = await axios.get(`${environment.HISTO_LEARN_API}getDataForEachTab/${name}`);
    // return JSON.parse(response.data.data);
    // console.log('response.data',response.data)
    return response.data
  } catch (err) {
    return rejectWithValue(err.message);
  }
});

export const caseSlice = createSlice({
  name: "case",
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDataForEachTab.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDataForEachTab.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getDataForEachTab.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      });
  },
});

export default caseSlice.reducer;
