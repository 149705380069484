import { configureStore } from "@reduxjs/toolkit";
import caseReducer from "./CaseSlice";
import tabReducer from "./TabNameSlice";
import singleCaseReducer from "./SingleEventCaseSlice";
import mapReducer from "./MapSlice";

export const store = configureStore({
  reducer: {
    case: caseReducer,
    tab: tabReducer,
    singleCase: singleCaseReducer,
    map: mapReducer,
  },
});
