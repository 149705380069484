import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { environment } from "../environment";
const backendUrl = process.env.REACT_APP_BACKEND_URL;

export const getSlideImageData = createAsyncThunk("getSlideImageData", async ({ eventName, caseName }, { rejectWithValue }) => {
  try {
    const response = await axios.get(`${environment.HISTO_LEARN_API}getSlideImageData/${eventName}/${caseName}`);
    // console.log(response.data)
    return response.data;
  } catch (err) {
    return rejectWithValue(err.message);
  }
});
export const getScaleData = createAsyncThunk("getScaleData", async ({ eventName, caseName }, { rejectWithValue }) => {
  try {
    const response = await axios.get(`${environment.HISTO_LEARN_API}getScaleData/${eventName}/${caseName}`);
    // console.log(response.data)
    return response.data;
  } catch (err) {
    return rejectWithValue(err.message);
  }
});

export const mapSlice = createSlice({
  name: "map",
  initialState: {
    imageData: [],
    scaleData:'',
    loading: false,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSlideImageData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSlideImageData.fulfilled, (state, action) => {
        state.loading = false;
        state.imageData = action.payload;
      })
      .addCase(getSlideImageData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      })

      .addCase(getScaleData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getScaleData.fulfilled, (state, action) => {
        state.loading = false;
        state.scaleData = action.payload;
      })
      .addCase(getScaleData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      });
  },
});


export default mapSlice.reducer;
