
const prod = {
    HISTO_LEARN_API:'/api/',
};

const dev = {
    HISTO_LEARN_API:'http://localhost:5000/api/',
};


export const environment = process.env.NODE_ENV === 'development' ? dev : prod;


