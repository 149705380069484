import axios from "axios";
import TileState from "ol/TileState";
import View from "ol/View";
import { OverviewMap } from "ol/control";
import TileLayer from "ol/layer/WebGLTile.js";
import { Projection } from "ol/proj";
import Zoomify from "ol/source/Zoomify";
import { useContext, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { environment } from "../../../environment";
import MapContext from "../MapContext";

const TileDependentLayer = () => {
  const { map } = useContext(MapContext);
  const source = useRef(null);
  const tileLayer = useRef(null);
  const overviewMapControl = useRef(null);
  const proj = useRef(null);
  const { imageData, scaleData: scale_data, loading: mapLoading } = useSelector((state) => state.map);
  const { width: imgWidth, height: imgHeight, tilesize: tileSize } = imageData;
  const { eventName, caseName } = useSelector((state) => state.tab);

  useEffect(() => {
    if (!map) return;
    source.current = new Zoomify({
      url: `${environment.HISTO_LEARN_API}getTileImage/${eventName}/${caseName}/`,
      size: [imgWidth, imgHeight],
      crossOrigin: "anonymous",
      zDirection: -1,
      tileSize: tileSize / 1,
      tilePixelRatio: 1,
    });

    var extent = source.current.getTileGrid().getExtent();
    source.current.setTileLoadFunction(async function (tile, src) {
      if (tile.getState() === TileState.LOADED) {
        return;
      }

      try {
        const response = await axios.get(src, {
          responseType: "blob",
        });

        if (response.status === 200) {
          const data = response.data;
          tile.getImage().src = URL.createObjectURL(data);
        } else {
          tile.setState(TileState.ERROR);
        }
      } catch (error) {
        tile.setState(TileState.ERROR);
      }
    });

    tileLayer.current = new TileLayer({
      source: source.current,
    });

    let px_To_mmVal = 1 / scale_data;

    proj.current = new Projection({
      units: "pixels",
      extent: [0, 0, imgWidth, imgHeight],
      metersPerUnit: px_To_mmVal / 1000000,
    });

    map?.setView(
      new View({
        resolutions: tileLayer.current.getSource().getTileGrid().getResolutions(),
        extent: extent,
        projection: proj.current,
        zoom: 7,
        constrainOnlyCenter: true,
      })
    );

    map?.getView().fit(extent, map.getSize());

    map?.addLayer(tileLayer.current);

    return () => {
      if (map) {
        map.removeLayer(tileLayer.current);
      }
    };
  }, [map]);

  useEffect(() => {
    if (!source.current) return;
    if (map) {
      overviewMapControl.current = new OverviewMap({
        className: "ol-overviewmap-map ol-custom-overviewmap",
        layers: [
          new TileLayer({
            source: source.current,
          }),
        ],
        collapseLabel: "\u00BB",
        label: "\u00AB",
        collapsible: true,
        collapsed: true,
        view: new View({
          resolutions: tileLayer.current.getSource().getTileGrid().getResolutions(),
          extent: [0, 0, imgWidth, -imgHeight], //working
          // extent: [imgWidth,0,0, -imgHeight],
          projection: scale_data ? proj.current : "",
          constrainOnlyCenter: true,
          maxZoom: 0,
          minZoom: 0,
          zoom: 0,
        }),
      });
      overviewMapControl.current.getOverviewMap().on("click", function (e) {
        map.getView().setCenter(e.coordinate);
      });

      map?.addControl(overviewMapControl.current);

      return () => {
        if (map) {
          map.removeControl(overviewMapControl.current);
        }
      };
    }
  }, [map, source.current]);

  return null;
};

export default TileDependentLayer;
