import { Container } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, BrowserRouter as Router, Routes, useLocation, useNavigate } from "react-router-dom";
import "./App.css";
import Footer from "./components/Footer";
import Header from "./components/Header";
import { getDataForEachTab } from "./features/CaseSlice";
import { getTabList } from "./features/TabNameSlice";
import DynamicTab from "./utlity/DynamicTab";
import SlideViewer from "./utlity/SlideViewer";

const AppRouter = () => {
  const { data, loading, error } = useSelector((state) => state.tab);
  const tabs = loading ? [""] : data;

  return (
    <Routes>
      {tabs &&
        tabs?.map((tab) => (
          <Route
            key={tab}
            path={tab}
            element={
              <>
                <DynamicTab />
                <Footer />
              </>
            }
          />
        ))}

      {tabs &&
        tabs?.map((tab) => (
          <Route
            key={tab}
            path={`${tab}/:caseName`}
            // path={`api/:eventName/:caseName`}
            element={
              <>
                <SlideViewer />
                <Footer />
              </>
            }
          />
        ))}
    </Routes>
  );
};

function AppContent() {
  const location = useLocation();
  let path = location.pathname.split("/");
  let pathName = path.length === 2 && path[1];
  const [name, setName] = useState(pathName);
  const dispatch = useDispatch();

  useEffect(() => {
    setName(pathName);
    sessionStorage.setItem("currentTab", location.pathname.slice(1));
  }, [location.pathname]);

  useEffect(() => {
    if (name) {
      dispatch(getDataForEachTab(name));
    }
  }, [dispatch, name]);

  return null;
}

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [headerVisible, setHeaderVisible] = useState(true);
  // const { headerVisible } = useSelector((state) => state.case);

  useEffect(() => {
    // console.log('location.pathname',location.pathname.split('/').length)
    if (location.pathname.split("/").length === 3) {
      setHeaderVisible(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    dispatch(getTabList());
  }, [dispatch]);

  useEffect(() => {
    const savedTabName = sessionStorage.getItem("currentTab");

    if (savedTabName) {
      navigate(`/${savedTabName}`);
    } else {
      navigate("/home");
    }
  }, [navigate]);

  return (
    <>
      {headerVisible && <Header />}
      <AppContent />
      <AppRouter />
    </>
  );
}

export default App;
