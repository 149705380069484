import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { environment } from "../environment";
const backendUrl = process.env.REACT_APP_BACKEND_URL;

export const getTabList = createAsyncThunk("getTabList", async (_, { rejectWithValue }) => {
  try {
    const response = await axios.get(`${environment.HISTO_LEARN_API}listOfName`);
    // console.log(response.data.subfolders)
    return response.data.subfolders;
  } catch (err) {
    return rejectWithValue(err.message);
  }
});

const tabNameSlice = createSlice({
  name: "tab",
  initialState: {
    data: [],
    caseName: "",
    eventName: "",
    loading: false,
    error: null,
  },
  reducers: {
    setCaseName: (state, action) => {
      state.caseName = action.payload;
    },
    setEventName: (state, action) => {
      state.eventName = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTabList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTabList.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action?.payload;
      })
      .addCase(getTabList.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload?.message;
      });
  },
});

export const { setCaseName, setEventName } = tabNameSlice.actions;
export default tabNameSlice.reducer;
