import Typography from "@mui/material/Typography";
import React from "react";

const Footer = () => {
  return (
    <div className="footer">
      <a
        href="https://www.medprimetech.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={process.env.PUBLIC_URL + "/foot.png"}
          alt="cilika"
          width='auto'
          height={50}
        />
      </a>
      <Typography>© 2024 MedPrime Technologies Pvt. Ltd.</Typography>
    </div>
  );
};

export default Footer;
