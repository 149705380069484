import { makeStyles } from '@mui/styles';
import React from "react";
import { NavLink } from "react-router-dom";


const useStyles = makeStyles((theme) => ({
  navLink: {
    textDecoration: 'none',
    color: theme.palette.text.primary,
    padding: theme.spacing(1),
    '&.active': {
      color: 'black',
      backgroundColor: '#2d6efd',
      borderRadius:10,
    },
  },
}));


const NavButton = ({ link, tag }) => {
  const classes = useStyles();

  return (
    <NavLink
      to={link}
      className={classes.navLink} activeclassname="active"
    >
      {tag}
    </NavLink>
  );
};

export default NavButton;
