import Map from "ol/Map";
import { FullScreen, ScaleLine } from "ol/control";
import { defaults as defaultInteractions } from "ol/interaction";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import TileDependentLayer from "./Layers/TileDependentLayer";
import MapContext from "./MapContext";

const MapContainer = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [map, setMap] = useState(null);
  const mapRef = useRef(null);
  const { imageData, scaleData, loading: mapLoading } = useSelector((state) => state.map);
  const { width: imgWidth, height: imgHeight, tilesize: tileSize } = imageData;

  useEffect(() => {
    let pathArray = location.pathname.split("/");
    if (pathArray.length === 3) {
      let map = new Map({
        interactions: defaultInteractions(),
        loadTilesWhileAnimating: true,
        loadTilesWhileInteracting: true,
      });

      map.setTarget(mapRef.current);
      setMap(map);
      return () => map.setTarget(null);
    }
  }, [location.pathname, dispatch]);

  useEffect(() => {
    if (!map) return;
    const scaleControl = new ScaleLine({
      units: "metric",
      className: "ol-scale-line",
    });

    // if (scaleData) {
      // map.addControl(scaleControl);
      map.addControl(new FullScreen());
    // }
    return () => {
      if (map) {
        // map.removeControl(scaleControl);
        map.removeControl(new FullScreen());
      }
    };
  }, [map]);

  return (
    <MapContext.Provider value={{ map }}>
      <div
        ref={mapRef}
        className="map"
      >
        {tileSize && (
          <>
            <TileDependentLayer />
          </>
        )}
      </div>
    </MapContext.Provider>
  );
};

export default MapContainer;
