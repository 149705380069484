import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { environment } from "../environment";
const backendUrl = process.env.REACT_APP_BACKEND_URL;

export const getDataForSingleEventCaseName = createAsyncThunk("getDataForSingleEventCaseName", async ({ eventName, caseName }, { rejectWithValue }) => {
  try {
    const response = await axios.get(`${environment.HISTO_LEARN_API}getDataForSingleEventCaseName/${eventName}/${caseName}`);
    // console.log(response.data)
    return response.data;
  } catch (err) {
    return rejectWithValue(err.message);
  }
});

export const singleEventCase = createSlice({
  name: "eventCase",
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDataForSingleEventCaseName.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDataForSingleEventCaseName.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getDataForSingleEventCaseName.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      });
  },
});

export default singleEventCase.reducer;
