import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import { styled } from "@mui/system";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import NavButton from "../utlity/NavButton";

const StyledAppBar = styled(AppBar)({
  backgroundColor: "#e3f2fd",
});


const theme = createTheme({
  palette: {
    text: {
      primary: "#858684",
    },
  },
});
function createPagesArray(tabs) {
  return (
    tabs &&
    tabs?.map((tab) => {
      return {
        link: tab,
        tag: capitalizeFirstLetter(tab),
      };
    })
  );
}

function capitalizeFirstLetter(string) {
  if (typeof string !== "string" || !string.length) {
    return string;
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const Header = () => {
  const { data, loading, error } = useSelector((state) => state.tab);
  const tabs = loading ? [""] : data;
  const pages = createPagesArray(tabs);
  const location = useLocation();
  let path = location.pathname.split("/");
  let pathName = path.length === 2 && path[1];
  const [name, setName] = useState(pathName);
  const [anchorElNav, setAnchorElNav] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  // Function to rearrange the array
  const rearrangeArray = (array) => {
    const newArray = array.slice();

    // Sort the array alphabetically, with 'home' coming first
    newArray.sort((a, b) => {
      if (a.tag === "Home") return -1;
      if (b.tag === "Home") return 1;
      return a.tag.localeCompare(b.tag);
    });

    return newArray;
  };

  const rearrangedArray = rearrangeArray(pages);

  useEffect(() => {
    setName(pathName);
    sessionStorage.setItem("currentTab", location.pathname.slice(1));
  }, [location.pathname]);

  return (
    <ThemeProvider theme={theme}>
      <StyledAppBar
        position="static"
        sx={{ px: 3 }}
      >
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon style={{ color: "black" }} />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {rearrangedArray.map(
                (page) =>
                  (name === "home" || name === page.link || page.link === "home") && (
                    <Button
                      key={page.tag}
                      sx={{ my: 2, color: "white", display: "block" }}
                    >
                      <NavButton
                        link={page.link}
                        tag={page.tag}
                      />
                    </Button>
                  )
              )}
            </Menu>
          </Box>

          {/* mobile end */}

          {/* tab start */}
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {rearrangedArray.map(
              (page) =>
                (name === "home" || name === page.link || page.link === "home") && (
                  <Button
                    key={page.tag}
                    sx={{ my: 2, color: "white", display: "block" }}
                    // sx={{ my: 2, color: "white", display: name === 'home' || name === page.link ? "block" : "none" }}
                  >
                    <NavButton
                      link={page.link}
                      tag={page.tag}
                    />
                  </Button>
                )
            )}
          </Box>
          <a
            href='https://www.medprimetech.com/'
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={process.env.PUBLIC_URL + "/top_logo.png"}
              alt="cilika"
              width='auto'
              height={50}
            />
          </a>
          {/* tab end */}
        </Toolbar>
      </StyledAppBar>
    </ThemeProvider>
  );
};

export default Header;



// https://histolearn.medprimetech.com/ggmc/SlideList/Case_2/case.info